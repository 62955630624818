$(document).ready(function(){

    // Automatic oppervlakte calculation
    $('.lengte, .breedte').on('keyup', function(){
        if (!$.isNumeric($(this).val().replace(',', '.'))) {
            $(this).val("");
        }

        var id = $(this).attr('tag-id');
        var lengte = $('[name="lengte_' + id + '"]').val().replace(',', '.');
        var breedte = $('[name="breedte_' + id + '"]').val().replace(',', '.');
        var opp = (breedte * lengte).toFixed(2).replace('.', ',');

        if (opp) {
            $('[name="oppervlakte_' + id + '"]').val(opp);
        } else {
            $('[name="oppervlakte_' + id + '"]').val('');
        }

        var counter = 0;
        $('.oppervlakte').each(function(index, value){
            if ($(this).val() && $(this).val().replace(',', '.') > 0) {
                ++counter;
            }
        });

        $('[name="room_count"]').remove();
        $('form').append('<input type="hidden" name="room_count" value="' + counter + '">');
    });
    $('.dikte').on('keyup', function(){
        if (!$.isNumeric($(this).val().replace(',', '.'))) {
            $(this).val("");
        }
    });
    $('.dikte').on('focusout', function(){
        if ($(this).val() && $(this).val().replace(',', '.') < 2) {
            $(this).val(2);
        }
    });
    $('.verdiep').on('keyup', function(){
        if (!$.isNumeric($(this).val().replace(',', '.'))) {
            $(this).val("");
        }
    });


    //Automatic dikte check
    $('.dikte').on('keyup', function(){
        var dekvloer = $('input[name=offerte_dekvloer]:checked').val();
        var vloerafwerking = $('input[name=offerte_vloerafwerking]:checked').val();
        if(dekvloer && vloerafwerking){
            if(vloerafwerking == 'Industriële egaline inclusief laklagen'){
                if($(this).val() < 4){
                    $(this).val(4);
                    alert('Hierbij is een minimum dikte van 4mm vereist.');
                }
            }else if(dekvloer == 'Planken' || dekvloer == 'Platen'){
                if($(this).val() < 3){
                    $(this).val(3);
                    alert('Hierbij is een minimum dikte van 3mm vereist.');
                }
            }else if(vloerafwerking == 'Verlijmd parket'){
                if($(this).val() < 2){
                    $(this).val(2);
                    alert('Hierbij is een minimum dikte van 2mm vereist.');
                }
            }
        }
    });


    //WERFADRES CHECKBOX
    var ander_adres = $('.werfadres input:checkbox:checked').length > 0;
    if(ander_adres == true){
        $('.werfadreshidden').show();
    }else{
        $('.werfadreshidden').hide();
    }
    $('.werfadres input:checkbox').change(function() {
        var ander_adres = $('.werfadres input:checkbox:checked').length > 0;
        if(ander_adres == true){
            $('.werfadreshidden').show();
        }else{
            $('.werfadreshidden').hide();
        }
    });


    $("input[name=offerte_dekvloer]:radio, input[name=offerte_vloerafwerking]:radio").change(function () {
        var dekvloer = $('input[name=offerte_dekvloer]:checked').val();
        var vloerafwerking = $('input[name=offerte_vloerafwerking]:checked').val();
        if(dekvloer && vloerafwerking){
            var table = $('table#step4');
            table.find('tr').each(function(){
                var tdval = $(this).find('td:last').find('input');
                if(tdval.val()){
                    if(vloerafwerking == 'Industriële egaline inclusief laklagen'){
                        if(tdval.val() < 4){
                            tdval.val(4);
                            alert('Hierbij is een minimum dikte van 4mm vereist.');
                        }
                    }else if(dekvloer == 'Planken' || dekvloer == 'Platen'){
                        if(tdval.val() < 3){
                            tdval.val(3);
                            alert('Hierbij is een minimum dikte van 3mm vereist.');
                        }
                    }else if(vloerafwerking == 'Verlijmd parket'){
                        if(tdval.val() < 2){
                            tdval.val(2);
                            alert('Hierbij is een minimum dikte van 2mm vereist.');
                        }
                    }
                }
            });
        }
    });


    // BUTTONS CLICK
    $('#menuToggle').click(function(event) {
        event.preventDefault();
        $('body').toggleClass('menu-open');
    });

    $('.close-button').click(function(event) {
        event.preventDefault();
        $('body').toggleClass('menu-open');
    });

    $('.overlay').click(function(event) {
        $('body').removeClass('menu-open');
    });

    $('.close-button').click(function(event) {
        $('body').removeClass('menu-open');
    });


});
